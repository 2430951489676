<template>
  <div class="centerComponent">
    <Title :title="title" long :showLine="showLine"></Title>
    <div class="aboutus" v-show="showPic">
      <img :src="imgSrc" alt="">
    </div>
    <slot name="menutabs"></slot>
<!--    <el-button v-show="showVideoPlayBtn" size="mini" plain icon="el-icon-video-play" @click="playVideo">观看视频</el-button>-->
    <span v-show="showVideoPlayBtn" class="play_video" @click="playVideo"><img src="../assets/images/play_btn.png" alt="play_video">播放视频</span>
  </div>
</template>

<script>
import Title from "@/components/Title"
export default {
  name: "CenterComponent",
  components: { Title },
  props: {
    title: {
      type: String,
      default: () => '关于富之源'
    },
    imgSrc: {
      type: String,
      default: () => require('../assets/images/ABOUTUS.png')
    },
    name: {
      type: String,
      default: () => 'trade'
    },
    showLine: {
      typeL: Boolean,
      default: () => true
    },
    showPic: {
      typeL: Boolean,
      default: () => true
    },
    showVideoPlayBtn: {
      typeL: Boolean,
      default: () => false
    }
  },
  data() {
    return {
    }
  },
  methods: {
    // 播放视频
    playVideo() {
      if (this.name === 'trade') {
        this.$message.error('视频资源整合中，请等待后续更新')
        return
      }
      this.$emit('play', `https://forryed.forryed.com/${this.name}.mp4`)
    }
  }
}
</script>

<style lang="scss" scoped>
.centerComponent {
  font-size: 0;
  height: 352px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  .title {
    font-size: 44px;
    margin-top: 116px;
    margin-left: 59px;
    font-weight: 500;
  }
  .line  {
    width: 80px;
    height: 1px;
    background: #387DEA;
    border: 2px solid #387DEA;
    margin-left: 47px;
    margin-top: 18px;
  }
  .aboutus {
    position: absolute;
    bottom: -10px;
    right: 34px;
    z-index: 9;
    img {
      height: 1.4rem;
    }
  }
  .play_video {
    cursor: pointer;
    position: absolute;
    top: 140px;
    left: 289px;
    color: #292A34;
    font-size: 16px;
    display: flex;
    align-items: center;
    z-index: 99;
    img {
      margin-right: 5px;
    }
  }
  ::v-deep .player {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }
}
</style>
<style>
  .el-icon-error {
    font-size: 20px;
  }
</style>
