import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isBlackText: true
  },
  getters: {
  },
  mutations: {
    updateBlack(state, data) {
      state.isBlackText = data
    }
  },
  actions: {
    'UPDATE_BLACK'({commit}, data) {
      commit('updateBlack', data)
    }
  },
  modules: {
  }
})
