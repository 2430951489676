<template>
  <div class="title animate__animated animate__fadeInUp">
    <div class="txt">{{ title }}</div>
    <div v-show="showLine" :class="{line: true, long: long, isCenter: isCenter}"></div>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    title: {
      type: String,
      default: () => "标题"
    },
    long: {
      type: Boolean,
      default: () => false
    },
    showLine: {
      type: Boolean,
      default: () => true
    },
    isCenter: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  z-index: 999;
  display: inline-block;
  &.isCenter {
    text-align: center;
  }
  .txt {
    font-size: 50px;
    font-family: Pingfang SCSB;
  }
  .line {
    width: 40px;
    height: 5px;
    background-color: #387DEA;
    margin-top: 11px;
    &.isCenter {
      margin: 11px auto 0;
    }
    &.long {
      width: 108px;
    }
  }
}
</style>
