<template>
  <div id="app" :class="{ isHome }">
    <!-- PC头部 -->
    <div class="header" :class="{ isHome }">
      <div class="logo">
        <a href="javascript:;">
          <img :src="isHome ? require('./assets/images/logo.png') : require('./assets/images/logo_bai.png')" alt="">
        </a>
      </div>
<!--      :class="{isBlack}"-->
      <nav>
        <router-link to="/">首页</router-link> |
        <router-link to="/about">关于富之源</router-link> |
        <router-link to="/culture">企业文化</router-link> |
        <router-link to="/developer">产业发展</router-link> |
        <router-link to="/news">新闻中心</router-link> |
        <router-link to="/join">加入我们</router-link> |
        <router-link to="/contact">联系我们</router-link> |
        <router-link to="/responsibility">社会责任</router-link>
      </nav>
    </div>
    <!-- 移动端头部 -->
    <div class="m-header" :class="{ 'm-isHome': isHome, whiteBg }">
      <img class="logo" :src="require('./assets/images/logo.png')" alt="">
      <!-- <img class="nav" @click="showMenu" :src="isHome || showMobileNav ? require('./assets/images/menu.png') : require('./assets/images/menu_black.png')" alt="menu"> -->
      <img ref="navBtn" class="nav" @click="showMenu" :src="isHome && !whiteBg ? require('./assets/images/menu.png') : require('./assets/images/menu_black.png')" alt="menu">
    </div>
    <!-- 移动端导航NAV -->
    <div v-show="showMobileNav" class="m-nav" @click="showMobileNav = false">
      <router-link to="/">首页</router-link>
      <router-link to="/about">关于富之源</router-link>
      <router-link to="/culture">企业文化</router-link>
      <router-link to="/developer">产业发展</router-link>
      <router-link to="/news">新闻中心</router-link>
      <router-link to="/join">加入我们</router-link>
      <router-link to="/contact">联系我们</router-link>
      <router-link to="/responsibility">社会责任</router-link>
    </div>
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer'

  export default {
    components: { Footer },
    data() {
      return {
        showMobileNav: false,
        whiteBg: false
      }
    },
    computed: {
      isHome() {
        return this.$route.name === 'home'
      },
      // 判断是否黑色导航文字
      isBlack() {
        return this.$store.state.isBlackText
      }
    },
    created() {
      document.addEventListener('click', e => {
        if (this.$refs.navBtn) {
          let isSelf = this.$refs.navBtn.contains(e.target)
          if (!isSelf) {
            this.showMobileNav = false
          }
        }
      })
      window.addEventListener('scroll', e => {
        this.showNavWhiteBg()
      })
    },
    methods: {
      // 显示菜单
      showMenu() {
        this.showMobileNav = this.showMobileNav ? false : true
      },
      showNavWhiteBg() {
        var scrollTop = document.documentElement.scrollTop
        if (scrollTop > 0) {
          this.whiteBg = true
        } else {
          this.whiteBg = false
        }
      }
    }
  }
</script>

<style lang="scss">
#app {
  font-family: 'PingFang SCR', Helvetica, Arial, sans-serif;
  color: #292A34;
  width: 86%;
  padding-top: 80px;
  margin: 0 auto;
  background: #F6F6F6;
  position: relative;
  @media screen and (min-width: 1074px) {
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      padding: 20px 40px 18px 55px;
      box-sizing: border-box;
      font-size: 16px;
      background-color: #387DEA;
      position: absolute;
      color: #FFFFFF;
      top: 0;
      z-index: 9;
      &.isHome {
        background-color: rgba(0,0,0,0);
        margin-top: 42px;
        .logo {
          img {
            height: 50px;
          }
        }
      }
      .nav {
        display: none;
      }
      .logo {
        img {
          height: 64px;
        }
      }
      @media screen and (max-width: 1268px) {
        .logo {
          display: none;
        }
      }
      nav {
        padding: 30px;
        &.isBlack {
          color: #292A34;
          a {
            color: #292A34;
            &.router-link-exact-active {
              &:after {
                background: #292A34;
              }
            }
          }
        }
        a {
          color: #FFF;
          padding: 0 10px;
          text-decoration: none;
          font-family: 'PingFang SCM';
          font-weight: 500;
          position: relative;
          &:hover {
            &:after {
              display: block;
              content: '';
              position: absolute;
              bottom: -7px;
              left: 50%;
              transform: translateX(-50%);
              width: 74px;
              height: 1px;
              background: #fff;
            }
          }
          &.router-link-exact-active {
            text-decoration: none;
            //border-bottom: 1px solid #FFF;
            &:after {
              display: block;
              content: '';
              position: absolute;
              bottom: -7px;
              left: 50%;
              transform: translateX(-50%);
              width: 74px;
              height: 1px;
              background: #fff;
            }
          }
        }
      }
    }
    .m-header {
      display: none;
    }
    &.isHome {
      padding-top: 0;
    }
  }
  @media screen and (max-width: 1074px) {
    .m-header {
      width: 100%;
      height: 1.66rem;
      line-height: 1.66rem;
      padding: 0 0.4rem 0 0.7rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      transition: all 0.2s;
      &.m-isHome {
        .logo {
          height: 0.5rem;
        }
      }
      &.whiteBg {
        background-color: #fff;
        box-shadow: 0 0.04rem 0.16rem #dfdfdf;
      }
      .logo {
        height: 0.78rem;
      }
      .nav {
        height: 0.4rem;
      }
    }
    .m-nav {
      width: 100vw;
      background-color: rgba(10, 10, 10, 0.66);
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      padding-top: 1.72rem;
      a {
        font-family: 'PingFang SCM';
        font-weight: 500;
        color: #FFFFFF;
        font-size: 0.22rem;
        display: block;
        width: 3.06rem;
        height: 0.46rem;
        line-height: 0.46rem;
        text-align: center;
        text-decoration: none;
        border-bottom: 0.02rem solid #fff;
      }
      @media screen and (min-width: 1074px) {
        display: none;
      }
    }
    .header {
      display: none;
    }
    &.isMHome {
       width: 100vw;
       background-color: #fff;
       .m-header {
         background-color: transparent;
       }
     }
  }
}
@media screen and (max-width: 1074px) {
  #app {
    width: 100%;
    padding-top: 0;
  }
}
</style>
