<template>
  <div class="player">
    <!-- <div class="video"> -->
      <i class="el-icon-close" @click="closeVideo" :class="{ isMobile: (deviceWidth <= 1074) }"></i>
      <video
        ref="videoPlayer"
        autoplay
        :src="videoSrc"
        controls
      />
    <!-- </div> -->
  </div>
</template>

<script>

export default {
  name: "VideoPlayer",
  props: {
    videoSrc: {
      type: String,
      default: () => ''
    },
    videoTitle: {
      type: String,
      default: () => '富之源集团'
    }
  },
  components: {
  },
  data() {
    return {
    }
  },
  created() {
    // this.$nextTick(() => {
    //   var video = this.$refs.videoPlayer
    //   video.onload = function () {
    //   }
    //   video.onerror = function() {
    //     console.log('error')
    //   }
    //   //不同浏览器情况不同，这里判断在该浏览器是否可以播放
    //   video.oncanplaythrough = function() {
    //     alert("This file can be played in the current browser");
    //   }
    // })
  },
  methods: {
    closeVideo() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .player {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    i {
     font-size: 30px;
     font-weight: 700;
     color: #FFFFFF;
     position: absolute;
     top: 70px;
     left: 88%;
     z-index: 9999;
     cursor: pointer;
      &.isMobile {
        top: 74%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    //::v-deep .easy-player-fill-container {
    //  .video-title {
    //    font-size: 18px;
    //  }
    //  .video-js .vjs-tech {
    //    object-fit: contain !important;
    //  }
    //}
    video {
      width: 100%;
      height: 99%;
      @media screen and (max-width: 1074px) {
        height: auto;
      }
    }
  }
</style>
